@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Gotham-book";
  src: url(./assets/fonts/Gotham-Book.otf) format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-book";
  src: url(./assets/fonts/Gotham-Bold.otf) format("opentype");
  font-weight: bold;
  font-style: normal;
}

#root {
  width: 100%;
  height: 100%;
}

html,
body {
  width: 100%;
  background: white;
}

body {
  margin: 0;
  font-family: Gotham-book, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
